import { render, staticRenderFns } from "./Intro.vue?vue&type=template&id=49da0474&scoped=true&"
import script from "./Intro.vue?vue&type=script&lang=ts&"
export * from "./Intro.vue?vue&type=script&lang=ts&"
import style0 from "./Intro.vue?vue&type=style&index=0&id=49da0474&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49da0474",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VImg,VLazy})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
