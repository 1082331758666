




































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Baffle from '@/helper/baffle/baffle';

@Component
export default class Intro extends Vue {
    isActive: boolean = false;
    implement = 0;
    scrollVariable: number = 0;
    line1: string = `I am a FrontEnd Software Developer form Denver, Colorado with ~3 years of experience.`;
    line2: string = `Even though I have worked with all three major-player of web development - Angular, React, and
                        Vue,`;
    line3: string = `I majorly work with React and Vue. I like working with Javascript.`;
    line4: string = `Recently I started Instagram page to share my knowledge about Javascript with other upcoming web
                        developer.`;
    line5: string = `When I am not programming, I like to cook, bike and/or hike, gym or play with Data Struture &
                        Algorithms.`;

    created() {
        console.log(
            `line1: ${this.line1.length}, line2: ${this.line2.length}, line3: ${this.line3.length}, line4: ${this.line4.length}, line5: ${this.line5.length}`
        );
    }
    onScroll(event: any) {
        this.scrollVariable = event.target.scrollTop;
        if (this.scrollVariable > 570) {
            this.implement++;
            this.animGlitch();
        }
    }

    animGlitch() {
        if (this.implement === 2) {
            let text = document.querySelector('#lines');
            let baffle = Baffle(text, {
                characters: '░▒ ▓/░ﻨ█▓█ﻪ▒░ო▓▒▓էհรﻨĸռﻪս▒▓░',
                speed: 100,
            });
            baffle.start();
            baffle.reveal(4000);
            console.log(baffle);
        }
    }
}
